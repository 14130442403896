@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  margin: auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;

  border-radius: 50%;
  border-style: solid;
  border-color: #5ab63c;
  border-right-color: transparent !important;

  animation: loader 1.1s infinite linear;
}

.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.8);
  z-index: 200;
}
