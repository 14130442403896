.icon_button {
  display: flex;
  align-items: center;
  justify-content: center;

  flex-shrink: 0;
  flex-grow: 0;

  padding: 10px;
  border-radius: 50%;

  cursor: pointer;
}
