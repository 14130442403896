.auth_page {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
}

.side {
  padding: 30px;
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.form_container {
  width: 100%;
  max-width: 342px;
  min-height: 350px;

  display: flex;
  flex-direction: column;

  flex-shrink: 0;
  flex-grow: 0;
}

.photo {
  background-image: url("/img/factory.png");
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.logo_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: rgba(115, 36, 61, 0.7);
  overflow: hidden;
}

.mobile {
  display: none;
  flex-direction: column;
}

.desktop {
  display: flex;
  flex-direction: column;
}

@media (max-width: 640px), (orientation: portrait) {
  .mobile {
    display: flex;
  }

  .desktop {
    display: none;
  }

  .photo {
    display: none;
  }
}
