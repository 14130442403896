.form_title {
  font-family: Roboto-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  text-transform: uppercase;
}

.typography {
  display: flex;
  align-items: center;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

.noWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.margin {
  margin-bottom: 12px;
}

.variant_default {
  font-size: 14px;
  line-height: 16px;
}

.varian_secondary {
  font-size: 12px;
  line-height: 14px;
}

.variant_h3 {
  font-weight: bold;
  font-size: 50px;
  line-height: 59px;
}

.variant_h5 {
  font-size: 24px;
  line-height: 28px;
}

.variant_h6 {
  font-size: 16px;
  line-height: 19px;
}

.color_default {
  color: #3e3e3e;
}

.color_contrast {
  color: white;
}

.color_textSecondary {
  color: #9b9b9b;
}

.color_error {
  color: #db4437;
}
