.menu_item {
  padding: 13px 10px 13px 16px;
  justify-content: flex-start;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.menu_item:hover {
  background-color: #eff8ec;
}
