.header {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 80px;

  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}

.header_button {
  min-width: 0px;
}

.container {
  height: 80px;
  display: flex;
  align-items: center;

  padding-top: 0;
  padding-bottom: 0;

  padding: 0 30px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
