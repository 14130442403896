.table_relative {
  position: relative;
  display: flex;
  flex: 1;
  height: 100%;
  overflow: hidden;
}

.table_wrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  border: 1px solid #e7e7e7;
  height: 100%;
  width: 100%;
}

.table {
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  overflow: auto;

  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  border-spacing: 0;

  width: 100%;
}

/* .table_header {
} */

.table_header .table_cell {
  background: #ebebeb;

  color: #444444;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
}

.table_row {
  height: 55px;
}

.table_cell {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #444444;
  background: #ffffff;
}

.table_cell_link {
  display: block;
  width: 100%;
  height: 100%;
  color: inherit;
}

.table_cell_padding {
  padding: 20px;
}

.table_row:nth-child(even) .table_cell {
  background: #fcfcfc;
}
