.group {
  display: flex;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;

  width: 50%;
  padding: 10px;
}

.column:first-child {
  padding-left: 0px;
}

.column:last-child {
  padding-right: 0px;
}

.row {
  display: flex;
  width: 100%;
}

@media (max-width: 640px), (orientation: portrait) {
  .group {
    flex-direction: column;
  }

  .column {
    padding: 0;
    width: 100%;
  }

  .row {
    flex-direction: column;
  }

  .row > * {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.row > * {
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;
}

.row > *:first-child {
  margin-left: 0;
}

.row > *:last-child {
  margin-right: 0;
}
