.list_item {
  display: flex;

  margin-bottom: 10px;
  min-height: 44px;
}

@media (max-width: 640px), (orientation: portrait) {
  .list_item {
    flex-direction: column;
  }
}
