.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;

  max-width: 1200px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.content {
  overflow: auto;
  padding: 30px;
  height: 100%;
  width: 100%;
}

.paper {
  display: flex;
  flex: 1;
  position: relative;
  background: #ffffff;
  max-height: 100%;
  overflow: hidden;
}

.separator {
  flex: 1;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 48px;
  flex-wrap: wrap;
}

.toolbar_margin {
  margin-bottom: 20px;
}
