.pagination {
  display: flex;
  flex-direction: row;

  height: 52px;
  background: #fcfcfc;
  box-shadow: 0px -3px 9px rgba(0, 0, 0, 0.07);
}

.select {
  border: none;
}
