.combobox2__control {
  height: 48px;
  border-radius: 4px !important;
  border: none !important;
  width: 66px;
  background: unset !important;
}

.combobox2__control.combobox2__control--is-focused {
  border-width: 1px !important;
  border-color: #5ab63c !important;
  box-shadow: none !important;
}

.combobox2__indicator-separator {
  display: none;
}

.combobox2__clear-indicator {
  cursor: pointer;
}

.combobox2__input {
  color: #444444;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.combobox2__single-value {
  width: calc(100% - 8px);
  color: #969fae;
  font-family: Roboto;
  font-weight: normal;
}

.combobox2__option {
  cursor: pointer;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  color: #3e3e3e;
  position: relative;
  padding: 16px;
}

.combobox2__option:active {
  background-color: transparent;
}

.combobox2__option--is-focused {
  background-color: rgba(151, 159, 174, 0.2) !important;
}

.combobox2__option--is-selected {
  background-color: #5ab63c !important;
}

.combobox2__option--is-selected::after {
  content: "";
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 4px;
  right: 14px;
  background-image: url("/static/icons/completed.svg");
}

.combobox2__placeholder {
  font-size: 14px;
  line-height: 16px;
  color: #9b9b9b;
}

.combobox2__menu {
  margin: 0;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  border-top: none;
}

.combobox2__menu-notice {
  font-size: 12px;
}

.combobox2__menu-list {
  padding: 0;
}

.combobox2__placeholder {
  font-weight: normal;
}

.combobox2__indicator {
  fill: black;
}
