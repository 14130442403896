.button {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  height: 42px;
  min-width: 200px;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  user-select: none;
}

.button_full_width {
  width: 100%;
}

.button.button_disabled,
.button_disabled.button_primary {
  background: lightgray;
  color: white;
}

.button_primary {
  background: #5ab63c;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
}

.button_color_default {
  color: #3e3e3e;
}

.button_color_default svg {
  fill: #3e3e3e;
}

.button_color_accent {
  color: #5ab63c;
}

.button_color_accent svg {
  fill: #5ab63c;
}

.button_color_contrast {
  color: white;
}

.button_color_contrast svg {
  fill: white;
}

.button_color_error {
  color: red;
}

.button_color_error svg {
  fill: red;
}
