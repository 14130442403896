.value {
  display: flex;
  align-items: center;
  color: #5ab63c;
  height: 32px;
  padding: 6px;
  line-height: 23px;
}

.option {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #5ab63c;
  height: 32px;
  padding: 6px;
  line-height: 23px;
}

.option:hover {
  background-color: rgba(151, 159, 174, 0.2) !important;
}

.value svg,
.option svg {
  fill: #5ab63c;
}
