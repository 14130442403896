.nav_bar {
  display: flex;

  flex-grow: 0;
  flex-shrink: 0;

  align-items: center;
  min-height: 48px;
}

.nav_item {
  margin-right: 60px;
  margin-bottom: 3px;
}

.nav_item_active {
  margin-bottom: 0;
  border-bottom: 3px solid #5ab63c;
}
