.container {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 16px;
}

.field {
  display: flex;

  border: 1px solid #e7e7e7;
  border-radius: 4px;

  width: 100%;
}

.field:focus-within {
  border-color: #5ab63c;
}

.error {
  border-color: #f44336;
}

.input {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  width: 100%;
  height: 46px;
  border-radius: 4px;
  padding: 16px;
}

.field svg {
  fill: #9b9b9b;
}

.field_label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #9b9b9b;
  width: 130px;

  padding: 12px;
}

.field_error {
  margin-top: 10px;
}

.inputAndError {
  display: flex;
  /* flex-grow: 0;
  flex-shrink: 0; */
  flex-direction: column;
  width: 100%;
}

@media (max-width: 640px), (orientation: portrait) {
  .field_label {
    justify-content: flex-start;
    padding: 12px 0;
  }

  .container {
    flex-direction: column;
    align-items: flex-start;
  }
}
