@font-face {
  font-family: "Roboto";
  src: url("/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Roboto-Thin";
  src: url("/fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Roboto-Light";
  src: url("/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "RobotoCondensed-Bold";
  src: url("/fonts/RobotoCondensed-Bold.ttf") format("truetype");
  font-weight: bold;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;

  color: #444444;
  background: #fafafa;
}

button,
input {
  border: 0;
  padding: 0;
  outline: none;
  background-color: transparent;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p,
h1 {
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;

  height: 100%;
  overflow: hidden;
}
