.fab {
  position: absolute;
  top: auto;
  left: auto;
  right: 20px;
  bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 56px;
  height: 56px;

  background: #5ab63c;
  border-radius: 50%;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12);
}
